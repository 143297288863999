import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useRouteMatch, useLocation, useHistory, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/LockOutlined';
import MuiLink from '@material-ui/core/Link';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AuthActions from '../../Redux/AuthRedux';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';

import Routes from '../../Routing/Routes';
import Seo from '../../Routing/Seo';

const useStyles = makeStyles(theme => ({
  layout: LayoutStyles.centered400Layout,
  paper: LayoutStyles.centered400Paper,
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  resetPassword: {
    marginTop: theme.spacing(3),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}));

const SignIn = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { organisation_slug: organisationSlug, holding_slug: holdingSlug } = match.params;
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    history: queryParam.get('history') || '',
    redirectUrl: queryParam.get('redirect_url') || '',
  };
  const goBack = urlParams.history === 'goBack';

  const currentUser = useSelector(state => state.auth.currentUser);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const isFetching = useSelector(state => state.auth.isFetching);

  const [emailOrPseudo, setEmailOrPseudo] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowpassword] = React.useState(false);

  React.useEffect(() => {
    if (isLoggedIn && urlParams.redirectUrl) history.push(urlParams.redirectUrl);
    else if (isLoggedIn && goBack) history.goBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const handleSubmit = event => {
    dispatch(AuthActions.signInRequest({ login: emailOrPseudo, password }));
    event.preventDefault();
  };

  const showCarenotebookDialog = false;
  // currentUser?.is_first_login &&
  // !currentUser?.has_carenotebook &&
  // currentUser?.families?.length < 2;

  const renderFormIfNotLogged = () => (
    <>
      <Avatar className={classes.avatar}>
        <LockIcon />
      </Avatar>

      <Typography component="h1" variant="h5">
        {t('WELLO.SIGN_IN.TITLE')}
      </Typography>

      <form method="POST" className={classes.form} onSubmit={handleSubmit}>
        <TextField
          name="email"
          value={emailOrPseudo}
          onChange={event => setEmailOrPseudo(event.target.value)}
          label={t('WELLO.SIGN_IN.EMAIL_OR_PSEUDO')}
          InputProps={{
            autoComplete: 'email',
            autoFocus: true,
          }}
          margin="normal"
          fullWidth
          required
        />
        <TextField
          name="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          label={t('WELLO.SIGN_IN.PASSWORD')}
          InputProps={{
            type: showPassword ? 'text' : 'password',
            autoComplete: 'current-password',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowpassword(!showPassword)}
                  onMouseDown={event => {
                    event.preventDefault();
                  }}
                  size="small"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          margin="normal"
          fullWidth
          required
        />
        <MuiLink
          component={Link}
          className={classes.resetPassword}
          variant="body1"
          to="/passwords/reset"
        >
          {t('WELLO.RESET_PASSWORD.LINK')}
        </MuiLink>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isFetching}
        >
          {t('WELLO.SIGN_IN.SUBMIT')}
        </Button>
        {organisationSlug && holdingSlug && (
          <MuiLink
            component={Link}
            className={classes.resetPassword}
            variant="body1"
            to={`/confirmations/on/${holdingSlug}/${organisationSlug}/new`}
          >
            {t('WELLO.RESEND_CONFIRMATION.LINK')}
          </MuiLink>
        )}
      </form>
    </>
  );

  return (
    <>
      <Helmet>
        {Seo.title(`${t('WELLO.SIGN_IN.TITLE')}${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`)}
        {Seo.description(`${t('WELLO.SIGN_IN.DESCRIPTION')}`)}
      </Helmet>

      <Grid container className={classes.layout}>
        <Paper className={classes.paper}>
          {isLoggedIn && !goBack && (
            <Redirect to={Routes.getSignInRedirectionLink(currentUser, showCarenotebookDialog)} />
          )}
          {!isLoggedIn && renderFormIfNotLogged()}
        </Paper>
      </Grid>
    </>
  );
};

export default SignIn;
