import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Redirect, Link } from 'react-router-dom';
import SignIn from '../SignIn';
import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';
import Routes from '../../../Routing/Routes';

type Props = {
  match: Object,
  classes: Object,
  organisation: Object,
  isLoggedIn: Boolean,
};
const styles = theme => ({
  layout: LayoutStyles.centered400Layout,
  textCenteredWithPadding: {
    color: theme.palette.common.white,
    width: '100%',
  },
  verticalContainer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  topContainer: {
    marginTop: '100px',
    backgroundColor: theme.palette.primary.main,
  },
  bottomContainer: {
    backgroundColor: theme.palette.grey[500],
  },
  button: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
});

function OrganisationSignInRequired({ match, classes, organisation, isLoggedIn }: Props) {
  const { t } = useTranslation();
  if (isLoggedIn) {
    // TODO: wtf ?
    return <Redirect to={Routes.customQuotationPath(organisation)} />;
  }
  return (
    <>
      <Grid
        container
        className={classNames(classes.layout, classes.topContainer, classes.verticalContainer)}
      >
        <Typography variant="body1" align="center" className={classes.textCenteredWithPadding}>
          {t('WELLO.SIGN_IN.REQUIRED')}
        </Typography>
      </Grid>
      <SignIn css={{ root: { marginTop: 0 } }} match={match} />
      <Grid
        container
        className={classNames(classes.layout, classes.bottomContainer, classes.verticalContainer)}
      >
        <Typography
          gutterBottom
          variant="body1"
          align="center"
          className={classes.textCenteredWithPadding}
        >
          {t('WELLO.SIGN_IN.NET_YET_REGISTRED')}
        </Typography>
        <Button
          component={Link}
          to={`/signup/on/${organisation.holding_slug}/${organisation.slug}/primary`}
          fullWidth
          variant="outlined"
          className={classes.button}
        >
          {t('WELLO.SIGN_IN.SIGN_UP')}
        </Button>
      </Grid>
    </>
  );
}

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(withStyles(styles)(OrganisationSignInRequired));
